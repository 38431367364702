<template>
  <div class="exchange">
    <img
      src="https://cdn.bubbleplan.cn/static/home/back_cc.png"
      alt=""
      class="exchange-bach"
      @click.stop="back()"
    />
    <div class="exchange-ul flex flex-center">
      <div
        v-for="(item, index) in exchangeList"
        :key="index"
        class="exchange-ul-li eli flex items-center flex-between"
        @mouseleave="handleMouseOver(index)"
        @mouseenter="handuleMouseenter(index)"
      >
        <div class="flex">
          <img :src="item.img" alt="" srcset="" class="eli-img" />
          <div
            style="margin-left: 30px; padding-top: 10px; padding-bottom: 9px"
            class="flex flex-direction flex-between"
          >
            <div style="font-size: 24px" class="eli-name">{{ item.name }}</div>
            <div style="font-size: 22px; color: rgba(255, 255, 255, 0.8)">
              {{ item.name }}{{ item.leftNum }}：{{ item.rightNum }}
            </div>
          </div>
        </div>
        <div
          :class="item.isState ? 'exchange-ul-button1' : 'exchange-ul-button'"
          @click="exchangeButton(item, index)"
        >
          兑换
        </div>
      </div>
    </div>
    <el-dialog title="兑换" :visible.sync="secedeShow" width="30%">
      <div class="exchange-dialog flex flex-direction items-center">
        <img :src="exchangeData.img" alt="" class="exchange-dialog-img" />
        <div class="exchange-dialog-input">
          <el-input v-model="count" placeholder="请输入兑换数量"></el-input>
        </div>
        <div class="exchange-dialog-rule">
          <div class="flex flex-between">
            <div class="exchange-dialog-rule-text">所需材料：</div>
            <div class="exchange-dialog-rule-num">
              {{
                exchangeIndex == 1
                  ? "芯片"
                  : exchangeIndex == 0
                  ? "芯片积分"
                  : exchangeIndex == 2
                  ? "晶体积分"
                  : "晶体"
              }}
            </div>
          </div>
          <div class="flex flex-between" style="margin-top: 35px">
            <div class="exchange-dialog-rule-text">服务费</div>
            <div class="exchange-dialog-rule-num">{{ exchangeData.rate }}%</div>
          </div>
          <div class="flex flex-between" style="margin-top: 35px">
            <div class="exchange-dialog-rule-text">消耗数量：</div>
            <div class="exchange-dialog-rule-num">
              {{ conversion }}
            </div>
          </div>
        </div>
        <div class="confirmConversion" @click="doExchange()">确认兑换</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      count: "",
      secedeShow: false,
      exchangeList: [
        // {
        //     img: require('../../img/user/gold.png'),
        //     name: '金币兑换积分',
        //     condition: '积分兑换金币1:1000',
        //     isState: false
        // },
        // {
        //     img: require('../../img/user/integral.png'),
        //     name: '积分兑换金币',
        //     condition: '积分兑换金币1:1000',
        //     isState: false
        // }
      ],
      exchangeData: {},
      exchangeIndex: 0,
    };
  },
  computed: {
    conversion() {
      if (this.exchangeData.rate != 0) {
        if (this.exchangeData.leftNum == 1) {
          return (
            (this.count * this.exchangeData.leftNum) /
            this.exchangeData.rightNum
          ).toFixed(0);
        } else {
          return (
            this.count * this.exchangeData.leftNum +
            this.count *
              this.exchangeData.leftNum *
              (this.exchangeData.rate / 100)
          ).toFixed(2);
        }
      } else {
        if (this.exchangeData.leftNum == 1) {
          return (
            (this.count * this.exchangeData.leftNum) /
            this.exchangeData.rightNum
          ).toFixed(0);
        } else {
          return (this.count * this.exchangeData.leftNum).toFixed(0);
        }
      }
    },
  },
  mounted() {
    this.exchange();
    this.userNum();
  },
  methods: {
    //兑换
    async doExchange() {
      if (!this.count) {
        return;
      }
      let integer = /^(?:0|(?:-?[1-9]\d*))$/;
      if (!integer.test(this.count)) {
        this.$message.error("请输入正确数量");
        return;
      }
      if (this.exchangeData.leftNum == 1) {
        if (this.count % this.exchangeData.rightNum != 0) {
          this.$message.error("请输入正确数量");
          return;
        }
      }

      const params = {
        id: this.exchangeData.id,
        count:
          this.exchangeData.leftNum == 1
            ? this.count / this.exchangeData.rightNum
            : this.count,
      };
      const { data, code } = await this.$ajax.doExchange(params);
      if (code == 200) {
        this.count = "";
        this.secedeShow = false;
        this.userNum();
      }
    },
    handleMouseOver(index) {
      this.exchangeList[index].isState = false;
    },
    handuleMouseenter(index) {
      this.exchangeList[index].isState = true;
    },
    exchangeButton(item, index) {
      this.exchangeIndex = index;
      this.exchangeData = item;
      this.count = "";
      this.secedeShow = true;
    },
    //兑换列表
    async exchange() {
      const { data } = await this.$ajax.exchangeList({
        pageNum: 1,
        pageSize: 10,
      });
      data.list.forEach((item, index) => {
        item.isState = false;
        this.exchangeList.push(item);
      });
    },
    //查询积分金币
    async userNum() {
      const { data, code } = await this.$ajax.userNum();
      if (code == 200) {
        this.userProperty(data);
        this.crystalNum();
      }
    },
    //查询晶体or晶体积分
    async crystalNum() {
      const { data, code } = await this.$ajax.crystalNum();
      if (code == 200) {
        this.usercrystalNumData(data);
      }
    },
    back() {
      history.back();
    },
  },
};
</script>
<style lang="scss">
.exchange {
  width: 100%;
  height: calc(100vh - 94px);
  background: #9a13a5;
  position: relative;
  .exchange-bach:hover {
    cursor: pointer;
  }
  .exchange-bach {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 60px;
    left: 230px;
    z-index: 9;
  }
  .exchange-ul {
    width: 1300px;
    padding-top: 29px;
    margin: auto;
    flex-wrap: wrap;
    .eli {
      padding: 50px 55px 55px 50px;

      .eli-img {
        width: 98px;
        height: 82px;
        border-radius: 25px;
      }

      &-name {
      }

      &-name:hover {
        color: rgb(218, 255, 0);
      }
    }
    .exchange-ul-button:hover {
      cursor: pointer;
    }
    .exchange-ul-button1:hover {
      cursor: pointer;
    }
    .exchange-ul-button {
      width: 100px;
      height: 48px;
      flex-shrink: 0;
      border-radius: 6.73px;
      background: #daff00;
      color: rgb(84, 0, 91);
      font-size: 20px;
      text-align: center;
      line-height: 48px;
    }

    .exchange-ul-button1 {
      width: 100px;
      height: 48px;
      flex-shrink: 0;
      border-radius: 6.73px;
      background: #daff00;
      border: 3px solid #54005b;
      color: rgb(84, 0, 91);
      font-size: 20px;
      text-align: center;
      line-height: 48px;
    }

    &-li {
      color: rgb(255, 255, 255);
      width: 611px;
      height: 209px;
      background: url("https://cdn.bubbleplan.cn/static/journalism/border.png")
        no-repeat;
      background-size: 100% 100%;
      margin-right: 23px;
      margin-bottom: 20px;
    }

    &-li:hover {
      width: 611px;
      height: 209px;
      background: url("https://cdn.bubbleplan.cn/static/journalism/border-hove.png")
        no-repeat;
      background-size: 100% 100%;
      margin-right: 23px;
      color: rgb(218, 255, 0) !important;
      margin-bottom: 20px;
    }
  }

  .el-dialog__title {
    color: #daff00;
    font-size: 24px;
    font-weight: 400;
  }

  .el-dialog {
    border-radius: 20px;
    background: #13212f;
  }

  .el-dialog__close el-icon el-icon-close {
    color: #64849a;
    font-size: 20px;
  }

  .el-dialog__header {
    border-bottom: 2px solid #021521;
  }

  .exchange-dialog {
    padding: 39px 18px;
    padding-bottom: 0;

    &-img {
      width: 104px;
      height: 104px;
      border-radius: 20px;
    }

    &-input {
      margin-top: 40px;
      width: 255px;
      height: 56px;

      .el-input__inner {
        width: 255px;
        height: 56px;
        border-radius: 50px;
        border: 1px solid #6b7f8f;
        background-color: transparent;
        font-size: 22px;
        color: #fff;
        text-align: center;
      }

      .el-input input::placeholder {
        color: rgb(78, 96, 111);
        font-size: 22px;
        text-align: center;
      }
    }

    &-rule {
      width: 100%;
      height: 225px;
      border-radius: 14px;
      background: #021521;
      margin-top: 40px;
      padding: 30px 26px;

      &-text {
        color: rgb(255, 255, 255);
        font-size: 22px;
      }

      &-num {
        color: rgb(147, 163, 175);
        font-size: 22px;
      }
    }
    .confirmConversion:hover {
      cursor: pointer;
    }
    .confirmConversion {
      margin: 43px auto;
      margin-bottom: 0;
      width: 338px;
      height: 64px;
      flex-shrink: 0;
      border-radius: 50px;
      background: #daff00;
      color: rgb(0, 0, 0);
      line-height: 64px;
      text-align: center;
      font-size: 22px;
    }
  }
}
</style>